import React from 'react';

import './Roadmap.css'
import './RoadmapMobile.css'

const roadmapContent = [
    {
        subtitle: "Phase1",
        title: "Launching Roadmap",
        content: "To create the best possible YakuBot experience for you, we have made it our mission to plan and " +
            "prepare everything down to the smallest detail. Accordingly, we have  created an exciting roadmap that" +
            " will fill you with joy and excitement ."
    },
    {
        subtitle: "Phase 2",
        title: "NFT Art",
        content: "Our artist burns with his soul for this project and puts a lot of time and love into the handmade " +
            "assets in order to offer you the greatest possible eye candy. High creativity and years of artistic " +
            "experience have now the chance to flow into the collection. YakuBots are the best possible, sweetest " +
            "protectors that you could ever imagine."
    },
    {
        subtitle: "Phase 3",
        title: "Smart Contracts",
        content: "To ensure that the YakuBots can stay by your side forever, we secure their ownership with solid " +
            "Smart Contracts and decentralized storage, so you are officially the owner of your bots after purchase " +
            "and can proudly show them off. We keep transaction costs to a minimum by choosing the Polygon network," +
            " so you can focus on the essentials - acquiring your YakuBot. We offer you the highest possible" +
            " transparency and publish the smart contracts for everyone to see on the Chain Explorer."
    },
    {
        subtitle: "Phase 4",
        title: "Community Events & Giveaways",
        content: "There will be a few lucky winners in our community events, who will be able to identify " +
            "themselves as owners of a YakuBot afterwards. The goal of the giveaway is of course to stimulate" +
            " the growth of our community at our early stages, but of course also to make you happy. We depend " +
            "on your support to promote our growth, and of course this should be rewarded."
    },
    {
        subtitle: "Phase 5",
        title: "Official Mint Event",
        content: "The loving creation of the NFT Art is complete, all Smart Contracts are ready to go, now it " +
            "can finally start, the big Mint Event begins. You are now able to buy your ticket to acquire your " +
            "own YakuBot."
    },
    {
        subtitle: "Phase 6",
        title: "Reveal of YakuBots",
        content: "The revealing functionality will be available on our website when one of the following " +
            "conditions are met: First, 50% of the available supply is transferred. Second, 2 months since our launch " +
            "date have been passed. This will give the opportunity to people to speed up the pickup function, " +
            "but also reassure those who are not in hurry that they will receive their YakuBots after a" +
            " two month period.."
    },
    {
        subtitle: "Phase 7",
        title: "Release and Listing of YakuToken",
        content: "Now you have your new brave companions by your side. However, in order to add fuctionalitty " +
            "and reward the possesors of yakubots we will release YakuToken, a token which in the early stages" +
            " will reward those who stake their yakubots at our website and on the later stages will be used as " +
            "a currency on our online game."
    },
    {
        subtitle: "Phase 8",
        title: "Crypto Game Development (Browsergame)",
        content: "Once our browser game is ready, you will be able to send your YakuBot into legendary battles " +
            "where you can win various rewards with different rarity levels, with which you can equip your bot, " +
            "or sell the received assets and earn Tokens.."
    },
    {
        subtitle: "Phase 9",
        title: "Future of YakuBots",
        content: "Now that we have implemented all the features we had planned. it's time to let your ideas " +
            "and wishes flow into the further design of the roadmap. Let's go the forward together"
    }
]

const Roadmap = () => {
    // generate the details of each phase
    const generateDetail = (detail) => {
        return (
            <div className="roadmap_detail">
                <div className="detail_description">
                    {detail.content}
                    <div className='new-line'>
                        {'\n'}
                    </div>
                </div>
            </div>
        )
    }

    // generate the phase (one whole phase frame)
    const generatePhase = (phase) => {
        return (
            <div key={phase.index } className={phase.index % 2 ? "roadmap_phase roadmap_right" : "roadmap_phase roadmap_left"}>
                <div
                    className={phase.index % 2 ? "roadmap_title roadmap_title_left" : "roadmap_title roadmap_title_right"}>{phase.subtitle}</div>
                <div className={phase.index % 2 ? "dot dot-left" : "dot dot-right"}></div>
                <div className={phase.index % 2 ? "arrow-left" : "arrow-right"}></div>
                <div className="roadmap_subtitle">{phase.title}</div>
                {generateDetail(phase)}
            </div>
        )
    }

    // main function for generating the roadmap
    return (
        <div className="roadmap">
            <div className="roadmap_headline">
                <u><h2>Roadmap</h2></u>
            </div>
            <div className="timeline_elements">
            </div>
            <div className="roadmap_content">
                {roadmapContent.map((phase, index) => {
                    phase.index = index
                    return generatePhase(phase)
                })
                }
            </div>
        </div>
    )
}

export default Roadmap