import React from 'react';

import './Team.css'

import TeamBannerImage from './assets/full_banner.png'
import AvatarDev from './assets/avatar_dev.png'
import AvatarArtist from './assets/avatar_artist.png'
import AvatarManager from './assets/avatar_manager.png'

const Team = () => {
    const TeamItem = ({image, name, title}) => {
        return (
            <div className="team-item">
                <div className="team-item-avatar">
                    <img src={image} alt=""/>
                </div>
                <div className="team-item-details">
                    <div className="team-item-details-name">{name}</div>
                    <div className="team-item-details-title">{title}</div>
                </div>
            </div>
        )
    }
    return (
        <div className="team">
            <div className="team-banner-image">
                <img src={TeamBannerImage} alt=""/>
            </div>
            <div className="team-title">
                <u><h2>Our Team</h2></u>
            </div>
            <div className="team-content">
                <TeamItem
                    image={AvatarArtist}
                    name="Yak's Factory"
                    title="Artist"
                />

                <TeamItem
                    image={AvatarManager}
                    name="Moderator"
                    title="Community Manager"
                />

                <TeamItem
                    image={AvatarDev}
                    name="DarkSy.eth"
                    title="Developer"
                />
            </div>
        </div>
    )
}

export default Team