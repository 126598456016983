import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

let toastQueue = []

const toast2 = (message, id) => {
    if (!toastQueue.find(q => q.id === id)) {
        toastQueue.push({time: Date.now(), id: id})
        toast(message, {
        })
    }
}

setInterval( () => {
    toastQueue = toastQueue.filter(q => q.time < Date.now() - 1000 * 2);
}, 2000)


export {
    toast2
}