import './Footer.css'

import React from "react";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer-toc">

            </div>
            <div className="footer-copyright">
                Copyright © {new Date().getFullYear() } | All rights reserved
            </div>
        </div>
    )
}

export default Footer