import './Lander.css'
import './LanderMobile.css'

import React from "react";

import YakuBotsBanner from './assets/yakubots_banner.png'
import YakuBotsBannerResized from './assets/yakubots_banner_resized.png'

const Lander = () => {
    return (
        <div className="lander">
            <img className="banner" src={YakuBotsBanner} alt="Yaku Bots Banner" />
            <img className="banner-resized" src={YakuBotsBannerResized} alt="Yaku Bots Banner" />
        </div>
    )
}

export default Lander