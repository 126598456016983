import React from 'react';
import {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import {toast2} from "../../modules/Toast2";

import WalletButtonConnect from './assets/walletbutton_connect.png'
import WalletButtonConnected from './assets/walletbutton_connected.png'
import WalletButtonSmall from './assets/wallet.png'

import './WalletButton.css'
import './WalletButtonMobile.css'

const WalletButton = ({setParentState, parentState}) => {
    const [currentAccount, setCurrentAccount] = useState("");

    const checkWalletIsConnected = async () => {
        const {ethereum} = window;

        if (!ethereum) {
            toast2("Make sure you have Metamask installed!", 100);
            return;
        }

        const accounts = await ethereum.request({method: 'eth_accounts'});

        if (accounts.length !== 0) {
            const account = accounts[0];
            setCurrentAccount(account);
            await getSigner();
            toast2("Wallet successfully connected!", 200)
        } else {
            setParentState({provider: null})
            setCurrentAccount("")
            toast2("Successfully disconnected all accounts!", 300)
        }
    }

    const connectWalletHandler = async () => {
        const {ethereum} = window;

        if (!ethereum) {
            toast2("Please install Metamask!", 400);
            return
        }

        try {
            const accounts = await ethereum.request({method: 'eth_requestAccounts'});
            console.log("Found an account! Address: ", accounts[0]);
            setCurrentAccount(accounts[0]);

            await getSigner();
        } catch (err) {
            console.log(err);
        }

        if (!isMaticNetwork()) {
            return;
        }
    }

    const isMaticNetwork = () => {
        const {ethereum} = window;

        if (ethereum) {
            if (ethereum.chainId === process.env.REACT_APP_CHAIN_ID) {
                return true;
            } else {
                return false;
            }
        }
    }

    const getSigner = async () => {
        try {
            const {ethereum} = window;

            if (!isMaticNetwork()) {
                return;
            }

            if (ethereum) {
                setParentState({provider: new ethers.providers.Web3Provider(ethereum)});
            } else {
                console.log("Ethereum object does not exist!");
            }
        } catch (err) {
            console.log(err);
        }
    }

    const connectWalletButton = () => {
        return (
            <div>
                <img onClick={connectWalletHandler}
                     className="wallet-button wallet-button-connect"
                     src={WalletButtonConnect}
                     alt=""
                />

                <img onClick={connectWalletHandler}
                     className="wallet-button-small"
                     src={WalletButtonSmall}
                     alt=""
                />
            </div>
        )
    }

    const loggedInButton = () => {
        return (
        <div className="wallet-button-connected">
            <img className="wallet-button"
                 src={WalletButtonConnected}
                 alt="" />
            <div className="wallet-button-wallet-address">{currentAccount.slice(0, 6) + "..." + currentAccount.slice(38, 42)}</div>
        </div>
        )
    }

    const chainChanged = () => {
        const {ethereum} = window;
        if(ethereum) {
            ethereum.on('chainChanged', () => {
                if (!isMaticNetwork()) {
                    toast2("Wrong Network! Please connect to to MATIC mainnet!", 500);
                    return;
                }
            });
        }
    }

    const accountChanged = () => {
        const {ethereum} = window;
        if(ethereum) {
            ethereum.on('accountsChanged', async () => {
                await checkWalletIsConnected()
            });
        }
    }

    useEffect(() => {
        checkWalletIsConnected()
        chainChanged()
        accountChanged()
    }, [])


    return (
        <div className="wallet-button">
            {currentAccount ? loggedInButton() : connectWalletButton()}
        </div>
    )
}

export default WalletButton;