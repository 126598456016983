import React from 'react';

import './Features.css'
import FeatureImg01 from './assets/feature_01.png'
import FeatureImg02 from './assets/feature_02.png'
import FeatureImg03 from './assets/feature_03.png'
import FeatureImg04 from './assets/feature_04.png'
import FeatureImg05 from './assets/feature_05.png'
import FeatureImg06 from './assets/feature_06.png'
import FeatureImg07 from './assets/feature_07.png'
import FeatureImg08 from './assets/feature_08.png'

const Features = () => {
    const FeatureElement = ({image, title, description}) => {
        return(
            <div className="feature-element">
                <div className="feature-image">
                    <img src={image} alt=""/>
                </div>
                <div className="feature-title">{title}</div>
                <div className="feature-description">{description}</div>
            </div>
        )
    }

    return(
        <div className="features">
            <div className="features-headline">
                <u><h3>Features</h3></u>
            </div>
            <div className="features-content">
                <FeatureElement
                    image={FeatureImg01}
                    title="Supply"
                    description="Only 1100 YakuBots will ever exist."
                />

                <FeatureElement
                    image={FeatureImg02}
                    title="Hand drawn"
                    description="All parts of the bots are hand drawn by Yak’s Factory"
                />

                <FeatureElement
                    image={FeatureImg03}
                    title="Transparent"
                    description="Fair launch and distribution via smart contracts. All Yakubots cost 100 MATIC"
                />

                <FeatureElement
                    image={FeatureImg04}
                    title="Future opportunities"
                    description="The project will be constantly developed"
                />

                <FeatureElement
                    image={FeatureImg05}
                    title="NFT Gaming"
                    description="You will be able to interact with your NFT in a browser game"
                />

                <FeatureElement
                    image={FeatureImg06}
                    title="NFT Staking"
                    description="Earn tokens while staking your NFT in our smart contract"
                />

                <FeatureElement
                    image={FeatureImg07}
                    title="Community Events"
                    description="Community events and many great giveaways are waiting for you"
                />

                <FeatureElement
                    image={FeatureImg08}
                    title="Token Holder Benefit"
                    description="Benefits regarding future airdrops for token holders"
                />
            </div>
        </div>
    )
}

export default Features