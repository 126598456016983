import React, {useState} from 'react';
import {ethers} from "ethers";
import {toast2} from "../../modules/Toast2";

import './Minting.css';

import YakuPoster from './assets/POSTER-4-4.png'
import YakuGif from './assets/PT-GIFT-3-2-2.gif'
import MintButtonReady from './assets/mintbutton.png'
import MintButtonPre from './assets/mintbutton_pre.png'

const Minting = () => {
    const contractAddress = process.env.REACT_APP_CONTRACT_ADDRESS;
    const abi = [{
        "inputs": [], "name": "mint", "outputs": [], "stateMutability": "payable", "type": "function"
    }]

    const [mintStatus, setMintStatus] = useState("");
    const [txURL, setTxURL] = useState("");

    const isMaticNetwork = () => {
        const {ethereum} = window;

        if (ethereum) {
            if (ethereum.chainId === process.env.REACT_APP_CHAIN_ID) {
                return true;
            } else {
                return false;
            }
        }
    }

    const mintNftHandler = async () => {
        try {
            const {ethereum} = window;

            if (!isMaticNetwork()) {
                toast2("Wrong Network! Please connect to to MATIC mainnet!", 110);
                return;
            }

            if (ethereum) {
                const provider = new ethers.providers.Web3Provider(ethereum);
                const signer = provider.getSigner();
                const nftContract = new ethers.Contract(contractAddress, abi, signer);

                toast2("Initialize payment", 121);
                setTxURL(null)

                setMintStatus("Initialize payment")
                let nftTxn = await nftContract.mint({value: ethers.utils.parseEther(process.env.REACT_APP_NFT_PRICE)});

                toast2("Minting... please wait", 122);
                setMintStatus("Minting... please wait")
                await nftTxn.wait();

                toast2(`NFT minted!`, 122);
                setTxURL(`https://polygonscan.com/tx/${nftTxn.hash}`)
                setMintStatus(`Minted, see transaction:`)
            } else {
                toast2("Polygon Wallet not connected ...", 123);
            }
        } catch (err) {
            if (err.code === -32603) {
                toast2("Error: " +
                    "Insufficient funds! " +
                    "100 Matic for mint + gas fee needed!", 112)
            } else if (err.code === "UNSUPPORTED_OPERATION") {
                toast2("Please connect your wallet first!", 129)
            } else {
                console.log(err)
            }

        }
    }

    const mintNftButton = () => {
        return (
            <div id="cf">
                <img alt="" onClick={mintNftHandler} className="mint-inactive" src={MintButtonReady}/>
            </div>
        )
    }

    const transactionURL = () => {
        return (
            <a target="_blank" rel="noreferrer" href={txURL}>PolygonScan</a>
        )
    }

    const MintFeature = () => {
        return (
            <div>
                {mintNftButton()}
                <div className="mint-feature">
                    <div className="mint-button">
                        <div className="mintStatus">{mintStatus}</div>
                        {txURL ? transactionURL() : ""}
                    </div>

                </div>
            </div>
        )
    }

    return (
        <div className="mint-content">
            <div className="mint-counter-content">
                <h3 className="mint-headline">Mint your YakuBot</h3>
                <img className="mint-yaku-gif" src={YakuGif} alt=""/>
                <MintFeature/>
            </div>

            <div className="yaku-poster">
                <img src={YakuPoster} alt="Yaku Poster"/>
            </div>
        </div>
    )
}

export default Minting;