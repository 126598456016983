import React, {useState} from "react";
import {ToastContainer} from "react-toastify";
import './App.css'

import Navbar from './../Navbar/Navbar'
import Lander from './../Lander/Lander'
import About from './../About/About'
import Features from './../Features/Features'
import Roadmap from './../Roadmap/Roadmap'
import Team from './../Team/Team'
import Footer from './../Footer/Footer'
import Minting from "../Minting/Minting";

const App = ({name}) => {
    const [state, setState] = useState({
        provider: null
    })

    return (
        <div>
            <Navbar setParentState={setState} parentState={state}/>
            <Lander />
            <Minting setParentState={setState} parentState={state}/>
            <About />
            <Features />
            <Roadmap />
            <Team />
            <Footer />
            <ToastContainer/>
        </div>
    )
}

export default App