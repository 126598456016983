import './Navbar.css'
import './NavbarMobile.css'

import React from "react";

import NavbarLogo from './assets/yakubots_meubar_logo.png'
import TwitterLogo from './assets/twitter_logo.png'
import DiscordLogo from './assets/discord_logo.png'
import OpenSeaLogo from './assets/opensea_logo.png'
import Docs from './assets/book-regular-24.png'

import WalletButton from "../WalletButton/WalletButton";

const NavLogo = () => {
    return (
        <div className="navbar-logo">
            <img src={NavbarLogo} alt="YakuBots Logo"/>
        </div>
    )
}

const NavMenuElement = ({image, text, link}) => {
    return (
        <li className="menu-item">
            <a href={link} target="_blank">
                <img src={image} alt={text}/>

                {text}
            </a>
        </li>
    )
}

const NavMenu = ({parentState, setParentState}) => {
    return (
        <div className="navbar-menu">
            <ul>
                <NavMenuElement image={TwitterLogo}
                                text="Twitter"
                                link="https://twitter.com/YakuBots"/>
                <NavMenuElement image={DiscordLogo}
                                text="Discord"
                                link="https://discord.gg/QaCuTXaG3s"/>
                <NavMenuElement image={OpenSeaLogo}
                                text="OpenSea"
                                link="https://opensea.io/collection/yakubots"/>
                <NavMenuElement image={Docs}
                                text="Docs"
                                link="https://docs.yakubots.com/"/>
            </ul>
            <WalletButton parentState={parentState} setParentState={setParentState} />
        </div>
    )
}

const Navbar = ({parentState, setParentState}) => {
    return (
        <div className="navbar">
            <NavLogo/>
            <NavMenu parentState={parentState} setParentState={setParentState}/>
        </div>
    )
}

export default Navbar