import React from 'react';

import './About.css'
import './AboutMobile.css'

import About1 from './assets/about_1.png'
import About2 from './assets/about_2.png'
import About3 from './assets/about_3.png'

const About = () => {
    return (
        <div>
            <div className="about">
                <div className="about-image-box-large">
                    <img src={About1} alt=""/>
                    <img src={About2} alt=""/>
                    <img src={About3} alt=""/>
                </div>

                <div className="about-image-box-small">
                    <img src={About1} alt=""/>
                </div>

                <div className="about-headline">
                    <u><h3>What are Yaku Bots</h3></u>
                </div>
                <div className="about-content">
                    <p>The year is 2130, and we find ourselves in an age dominated by technology, far removed from what
                        we would have called modern a century ago, let alone imagined. The idea of one day colonizing
                        Mars, which seemed so unimaginable at the time, can only be wearily smiled at today. We have
                        already developed by the technological progress to a multi-planetary highly intelligent species.
                        However, we humans and animals are not the only inhabitants of these planets. We have needed
                        assistance over time as the work has become too heavy and extensive. Accordingly, companies have
                        taken up the cause of developing highly intelligent, powerful robots.
                        Unfortunately, there are recurring hacker attacks, which let the high-performance robots be used
                        as a weapon against us.</p>
                    <p>This is exactly where we come in. We provide you with a partner that offers no interfaces to the
                        outside world, and is therefore unhackable as long as you keep an eye on your soon-to-be new
                        best friend and companion. Our latest development - the YakuBot! Think of our Yaku's as a kind
                        of cute, playful pet, doing exactly what you ask it to do. However, with a difference. Should
                        your Yaku sense danger, it will defend you and your loved ones down to the smallest electronic
                        components!</p>

                    <p>Even on your interplanetary travels, your Yaku will always be by your side to entertain you in
                        any situation as well as protect you.</p>

                    <p>We also give a lifetime warranty that includes the life of all your successor generations. So
                        don't stop reproducing!</p>

                </div>
            </div>
        </div>
    )
}

export default About